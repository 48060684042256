#left-banner,
#right-banner {
  width: 12vw;
}
#title-group {
  flex-direction: row;
}
#title {
  font-size: 2.2vw;
}
#timesaver-description {
  font-size: 1.3vw;
}
#timesaver-instruction {
  font-size: 1vw;
}
#timesaver-description,
#timesaver-instruction {
  padding-bottom: 5px;
  width: 37vw;
}
#select-box {
  width: 10vw;
}
.result-lines {
  line-height: 3vw;
  font-size: 1.8vw;
}
.result-value {
  font-weight: bold;
  font-size: 2.4vw;
  color: green;
}

@media (max-width: 768px) {
  #main-box {
    max-width: 100%;
  }
  #left-banner {
    width: 40vw;
  }
  #right-banner {
    height: 3px;
    width: 0;
  }
  #title-group {
    flex-direction: column;
  }
  #timesaver-description {
    font-size: 16px;
  }
  #timesaver-instruction {
    font-size: 15px;
  }
  #timesaver-description,
  #timesaver-instruction {
    width: 80vw;
  }
  #title {
    font-size: 22px;
  }
  #select-stack {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  #select-box {
    width: 120px;
  }
  .result-lines {
    font-size: 22px;
    line-height: 32px;
  }
  .result-value {
    font-size: 24px;
  }
}
